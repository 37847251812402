import loadable from "@loadable/component";
import React, { useEffect } from "react";

import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";

import useStyles from "@hooks/useStyles";
import Button from "@material-ui/core/Button";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

const CardRecommended = () => {
	const classes = useStyles("Home");

	const services = [
		{
			href: "/client/bookings/details",
			imgSrc:
				"https://res.cloudinary.com/liaison-inc/image/upload/c_fill,f_auto,q_auto,w_600/services/homeguide/thumbnails/handyman.jpg",
			primaryText: "Handyman services",
		},
		{
			href: "/client/bookings/details",
			imgSrc: "https://static.lessons.com/assets/images/bg/thumbs/swimming.jpg",
			primaryText: "Swimming lessons",
		},
		{
			href: "/client/bookings/details",
			imgSrc:
				"https://res.cloudinary.com/liaison-inc/image/upload/c_fill,f_auto,q_auto,w_600/services/homeguide/thumbnails/handyman.jpg",
			primaryText: "Handyman services",
		},
		{
			href: "/client/bookings/details",
			imgSrc:
				"https://static.lessons.com/assets/images/bg/thumbs/horseback-riding.jpg",
			primaryText: "Horseback Riding Lessons",
		},
		{
			href: "/client/bookings/details",
			imgSrc:
				"https://static.lessons.com/assets/images/bg/thumbs/basketball.jpg",
			primaryText: "Basketball trainers",
		},
		{
			href: "/client/bookings/details",
			imgSrc: "https://static.lessons.com/assets/images/bg/thumbs/golf.jpg",
			primaryText: "Golf lessons",
		},
	];

	return (
		<React.Fragment>
			<Box fontSize={24} mb={6} fontWeight={600}>
				Recommended for you
			</Box>

			<Grid container spacing={6}>
				<Grid item xs={12}>
					<Paper variant="outlined">
						<Box p={"12px 24px"}>
							<List component="nav">
								{services.map((service, index) => (
									<ListItem
										key={index}
										button
										component="a"
										href={service.href}
										target="_blank"
										disableGutters
										classes={{
											secondaryAction: classes.secondaryAction,
											container: classes.listItemContainer,
											root: classes.listItem,
										}}
									>
										<ListItemAvatar>
											<Avatar
												src={service.imgSrc}
												variant="rounded"
												className={classes.avatar}
												imgProps={{
													loading: "lazy",
													decoding: "async",
												}}
											/>
										</ListItemAvatar>
										<ListItemText
											primary={service.primaryText}
											classes={{
												primary: classes.listPrimary,
												secondary: classes.listSecondary,
											}}
										/>
										<ListItemSecondaryAction className={classes.secondaryRoot}>
											<Button
												color="primary"
												style={{ backgroundColor: "rgba(14, 107, 230, 0.04)" }}
											>
												Search
											</Button>
										</ListItemSecondaryAction>
									</ListItem>
								))}
							</List>
						</Box>
					</Paper>
				</Grid>
			</Grid>
		</React.Fragment>
	);
};

CardRecommended.propTypes = {};
export default CardRecommended;

export const loader = (route) =>
	loadable(() => import("./CardRecommended.jsx"), {
		ssr: !!route.ssr,
	});
